import React, { Component } from "react"
import {
  ContactusBox,
  Privacypolicy,
  PrivacyPolicyBox,
} from "../../components/UI"
import { Modal, ModalBody, Button } from "reactstrap"
import axios from "axios"
import styled from "styled-components"
import { navigate } from "gatsby"
import "../Contacts/Contact.css"

class ContactusModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      email: "",
      phone: "",
      message: "",
      companyName: "",
      intrestIn: "",
      countries: [],
      success: false,
    }
  }
  componentDidMount() {
    console.log("country......")
    axios
      .get(`https://appbackend.tekorero.com/api/v2.0/countries`)
      .then((res) => {
        this.setState({ countries: res.data })
      })
      .catch((err) => {})
  }
  changeHandler = (e) => {
    console.log(e.target.value)
    console.log(e.target.name)
    this.setState({ [e.target.name]: e.target.value })
    console.log(this.state.intrestIn)
  }
  submitHandler = (e) => {
    const {
      email,
      message,
      name,
      phone,
      companyName,
      intrestIn,
      country,
    } = this.state
    console.log(this.state.intrestIn)
    if (!email && !phone) {
      alert("Email or Phone number must be provided")
      return
    }
    e.preventDefault()
    const data = {
      name: name || "",
      email: email || "",
      phone: phone || "",
      message: message || "",
      companyName: companyName || "",
      intrestIn: intrestIn || "",
      country: country || "",
      website: "www.tekorero.com",
    }
    axios
      .post(`https://appbackend.tekorero.com/api/v2.0/contactUs`, data)
      .then((res) => {
        alert("Thanks for reaching out, we will revert soon")

        navigate("/features")
      })
      .catch((err) => {})
  }

  render() {
    const { name, email, phone, message, companyName } = this.props

    const { open, toggle } = this.props

    return (
      <Modal isOpen={open} toggle={toggle} size="lg">
        <ModalBody style={{ padding: 0 }}>
          <PrivacyPolicyBox>
            <ContactusBox>
              {" "}
              <div>
                <div isOpen={open} onClick={toggle}>
                  <i
                    className="fa fa-close"
                    aria-hidden="true"
                    style={{
                      color: "black",
                      fontSize: "1.5rem",
                      display: "flex",
                      cursor: "pointer",
                      justifyContent: "flex-end",
                    }}
                  />
                </div>
                <h2
                  style={{
                    color: "DEEPSKYBLUE",
                    textAlign: "center",
                    fontSize: "22px",
                  }}
                >
                  GET IN TOUCH WITH US
                </h2>

                <p style={{ textAlign: "left", fontSize: "14px" }}>
                  We look forward to your feedback, reviews, inputs on features
                  and more to make Korero better!
                </p>
              </div>
              <div className="contact_info_main_box_contact">
                <div className="sub_contact_info_main_box">
                  <div className="sub_contact_info_icon_pricing_contact">
                    <i
                      class="fa fa-map-marker"
                      style={{
                        color: "orange",
                      }}
                    />
                  </div>
                  <div className="sub_contact_info_pricing_contact">
                    748(P), Jayadev Vihar, Bhubaneswar, Odisha, India,751013
                  </div>
                </div>
                <br />
                <div className="sub_contact_info_main_box">
                  <div className="sub_contact_info_icon_pricing_contact">
                    <i class="fa fa-envelope" style={{ color: "orange" }} />
                  </div>
                  <div className="sub_contact_info_pricing_contact">
                    support@tekorero.com
                  </div>
                </div>
                <br />
                <div className="sub_contact_info_main_box">
                  <div className="sub_contact_info_icon_pricing_contact">
                    {" "}
                    <i class="fa fa-phone" style={{ color: "orange" }} />
                  </div>

                  <div className="sub_contact_info_pricing_contact">
                    +91-7846892841
                  </div>
                </div>

                <br />

                <div className="sub_contact_info_main_box">
                  <div className="sub_contact_info_icon_pricing_contact">
                    <i class="fa fa-whatsapp" style={{ color: "orange" }} />
                  </div>
                  <div className="sub_contact_info_pricing_contact">
                    {" "}
                    +91-7846892841
                  </div>
                </div>
                <br />
              </div>
              <div className="contact-form">
                <div className="contact-form-field">
                  <input
                    style={{
                      height: "45px",
                      padding: "0 15px!important",

                      width: "100%",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                    }}
                    type="text"
                    name="name"
                    color="grey"
                    placeholder="Name "
                    onChange={this.changeHandler}
                    value={name}
                  />
                </div>
                <br />

                <div className="contact-form-field">
                  <input
                    style={{
                      height: "45px",
                      padding: "0 15px!important",

                      width: "100%",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                    }}
                    type="text"
                    name="email"
                    placeholder="Email "
                    onChange={this.changeHandler}
                    value={email}
                  />
                </div>
                <br />
                <div className="contact-form-field">
                  <input
                    style={{
                      height: "45px",
                      padding: "0 15px!important",
                      fontFamily: "normal",
                      width: "100%",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                    }}
                    placeholder="Phone #"
                    type="text"
                    name="phone"
                    onChange={this.changeHandler}
                    value={phone}
                  />
                </div>
              </div>
              {/* <br /> */}
              <br />
              <div
                className="contact-form"
                // style={{
                //   display: "flex",
                //   width: "100%",
                //   justifyContent: "space-between",
                // }}
              >
                <div className="contact-form-field">
                  {" "}
                  <input
                    style={{
                      width: "48%",
                      height: "44px",
                    }}
                    type="text"
                    name="companyName"
                    placeholder="Company's Name "
                    onChange={this.changeHandler}
                    style={{
                      padding: "15px",
                      height: "44px",
                      width: "100%",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                    }}
                    value={companyName}
                  />
                </div>
                <br />
                <div className="contact-form-field">
                  <select
                    name="intrestIn"
                    onChange={this.changeHandler}
                    style={{
                      padding: "12px",

                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                      width: "100%",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                    required
                  >
                    <option value="" disabled selected hidden>
                      Interested in
                    </option>
                    <option value="Schedule a demo " class="others">
                      Schedule a demo{" "}
                    </option>
                    <option value="Explore On premise option" class="others">
                      Explore On premise option
                    </option>
                    <option
                      value=" Integration with 3rd party apps "
                      class="others"
                    >
                      {" "}
                      Integration with 3rd party apps{" "}
                    </option>
                    <option value="Product development" class="others">
                      Product development
                    </option>
                    <option value="Know more about Features" class="others">
                      Know more about Features
                    </option>
                    <option value="Feedback" class="others">
                      Feedback{" "}
                    </option>
                  </select>
                </div>
                <br />
                <div
                  className="contact-form-field"
                  style={{ borderColor: "whitesmoke" }}
                >
                  <select
                    name="country"
                    onChange={this.changeHandler}
                    required
                    style={{
                      padding: "12px",
                      backgroundColor: "whitesmoke",
                      border: "1px solid silver",
                      width: "100%",
                      fontSize: "14px",
                      fontFamily: "Roboto",
                    }}
                  >
                    {this.state.countries.map((country) => {
                      return (
                        <>
                          <option value="" disabledselected hidden>
                            Country
                          </option>
                          <option value={country.countryName} class="others">
                            {country.countryName}{" "}
                            {` + ${country.countryDialCode}`}
                          </option>
                        </>
                      )
                    })}
                  </select>
                </div>
              </div>
              <br />
              <textarea
                style={{
                  padding: 10,

                  width: "100%",
                  height: "50%",
                  backgroundColor: "whitesmoke",

                  border: "1px solid lightblue",
                }}
                name="message"
                placeholder="Message............"
                onChange={this.changeHandler}
                value={message}
                // name="input_3"
                // id="input_9_3"
                // class="textarea medium"
                // aria-describedby="gfield_description_9_3"
                // aria-required="true"
                // aria-invalid="false"
                rows="5"
                cols="50"
              ></textarea>
              <button
                style={{
                  float: "right",
                  width: "150px",
                  backgroundColor: "black",
                  color: "orange",
                  marginLeft: "150px",
                  marginTop: "10px",
                  border: "none",
                  height: "5vh",
                }}
                onClick={this.submitHandler}
                // disabled
              >
                SUBMIT
              </button>
              <br />
              <br />
              <br />
            </ContactusBox>
          </PrivacyPolicyBox>
        </ModalBody>
      </Modal>
    )
  }
}

export default ContactusModal
