import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Text from "../modules/Pricing/Pricing"

const Pricing = () => (
  <Layout>
    <SEO title="Pricing" 
    description="You have the freedom to change your subscription, in-app upgrades are allowed at any 
    time however subscription downgrade is allowed after a period of 30 days with the higher subscription.
    For paid versions, Korero provides a trial period that allows you to experience features and functionalities."/>
    <Text />
    <script src="https://platform.linkedin.com/in.js" type="text/javascript">
      {" "}
      lang: en_US
    </script>
  </Layout>
)

export default Pricing
