import React, { Component } from "react"
import styled from "styled-components"
import { loginUrl } from "../../config/keys"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import BLUEBOXIMAGE from "../../images/blueboximage.png"
class HomePageBlueBox extends Component {
  render() {
    return (
      <Box>
        <LeftBox>
          <img src={BLUEBOXIMAGE} alt="blueboxleftimage" width="100%" />
        </LeftBox>
        <RightBox>
          <RightBoxHeader>Enterprise Plans</RightBoxHeader>
          <RightBoxContent>
            Looking for custom solutions tailored for your industry and business
            growth? Your Search ends here. Let our team help you build your CRM
            with our tried and tested platform. Build your custom solution with
            reduced time and the agility your business needs. Scale Flexibly
            without any hassle, whenever you need.
          </RightBoxContent>
          <RightBoxButton>
            <TrialLink1
              onClick={(e) => {
                // To stop the page reloading
                // e.preventDefault()
                // Lets track that custom click
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category: "start your free trial  button in navbar ",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "Click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label: "Start your free trial button in navbar",
                  // number - optional - Numeric value associated with the event. (e.g. A product ID)
                  value: 43,
                })
                //... Other logic here
              }}
              href={`/contacts/`}
              target="_blank"
            >
              Contact Us
            </TrialLink1>
          </RightBoxButton>
        </RightBox>
      </Box>
    )
  }
}
export default HomePageBlueBox

const Box = styled.div`
  display: grid;
  grid-template-columns: 35% 60%;
  grid-gap: 2em 2em;

  background: #2a1e5c;

  @media screen and (max-width: 600px) {
    grid-template-columns: 100%;
  }
`
const LeftBox = styled.div``
const RightBox = styled.div`
  margin: 30px;
`
const RightBoxHeader = styled.div`
  font-family: Roboto;

  font-weight: 100;
  font-size: 2.5em;
  line-height: 56px;
  letter-spacing: -0.5px;
  color: #ffffff;
`
const RightBoxContent = styled.p`
  font-family: Roboto;
  font-size: 1.125em;
  letter-spacing: -0.5px;
  text-align: left;
  color: #ffffff;
  margin-top: 30px;
  font-weight: 100;
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const RightBoxButton = styled.div`
  padding: 20px 1px;
  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`
const TrialLink1 = styled.a`
  /* display: inline-block; */
  text-decoration: none !important;
  font-family: "Roboto";
  padding: 11px 20px;

  background: #29c8ff;
  border-radius: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 100;
  font-size: 1.075em;
  width: 148px;
  color: #ffffff !important;
  @media screen and (max-width: 600px) {
    text-align: center;
    width: 100%;
  }
`
