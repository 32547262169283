import React from "react"
import { Table } from "reactstrap"
import tick from "../../images/tick.png"
import crossimg from "../../images/cross.png"
import styled from "styled-components"
class Compare extends React.Component {
  render() {
    const check = tick

    const cross = crossimg
    return (
      <div className="main15">
        <Sticky>
          <CompareSubHeaderMain>
            <CompareSubHeaderOne> </CompareSubHeaderOne>
            <CompareSubHeaderFree>FREE</CompareSubHeaderFree>
            <CompareSubHeaderStarter>STARTER</CompareSubHeaderStarter>
            <CompareSubHeaderProfessional>PRO</CompareSubHeaderProfessional>
            <CompareSubHeaderProfessionalPlus>
              PRO+
            </CompareSubHeaderProfessionalPlus>
          </CompareSubHeaderMain>{" "}
        </Sticky>
        <StickyTableHeader>
          <CompareTableHeader>Lead Management</CompareTableHeader>
        </StickyTableHeader>
        <TableWrapper>
          <table>
            <tbody>
              <tr>
                <td>
                  Opportunity Management&nbsp;{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>

                <td>
                  <img src={check} alert="check" className="img_width" />
                </td>
                <td>
                  <img src={check} alert="check" className="img_width" />
                </td>
                <td>
                  <img src={check} alert="check" className="img_width" />
                </td>
                <td>
                  <img src={check} alert="check" className="img_width" />
                </td>
              </tr>

              <tr>
                <td>
                  Contact Management&nbsp;{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>

                <td>
                  <img src={check} alert="check" className="img_width" />
                </td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                  <Remark>Limited to 10,000 contacts</Remark>
                </td>
                <td>
                  <img src={check} alert="check" />
                  <Remark>Unlimited</Remark>
                </td>
                <td>
                  <img src={check} alert="check" className="img_width" />
                </td>
              </tr>

              <tr>
                <td>
                  Auto customer data population&nbsp;{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>{" "}
                </td>
                <td>__</td>
                <td>
                  <img src={check} alert="check" />
                  <Remark>5times / peruser / permonth</Remark>
                </td>
                <td>
                  <img src={check} alert="check" />
                  <Remark>25 times / peruser / permonth</Remark>
                </td>
                <td>
                  <img src={check} alert="check" className="img_width" />
                </td>
              </tr>

              <tr>
                <td>
                  Multi currency support&nbsp;{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>
                  <img src={check} alert="check" />
                </td>
                <td>
                  <img src={check} alert="check" />
                </td>
                <td>
                  <img src={check} alert="check" />
                </td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <StickyTableHeader>
          <CompareTableHeader>Manage Accounting Periods</CompareTableHeader>
        </StickyTableHeader>
        <TableWrapper>
          <table>
            <tbody>
              <tr>
                <td>
                  Configurable accounting period{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>{" "}
                </td>
                <td>__</td>
                <td>
                  <img src={check} alert="check" />
                </td>
                <td>
                  <img src={check} alert="check" />
                </td>
                <td>
                  <img src={check} alert="check" />
                </td>
              </tr>

              <tr>
                <td>
                  Reports aligned with accounting periods{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>
                  <img src={check} alert="check" />
                  <Remark>FY only</Remark>
                </td>
                <td>
                  <img src={check} alert="check" />
                  <Remark>FY, QTD, MTD and weekly basis</Remark>
                </td>
                <td>
                  <img src={check} alert="check" />
                </td>
              </tr>

              <tr>
                <td>
                  Analytics aligned with accounting periods{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                  <Remark>FY only</Remark>
                </td>
                <td>
                  <img src={check} alert="check" />
                  <Remark>FY, QTD, MTD and weekly basis</Remark>
                </td>
                <td>
                  <img src={check} alert="check" />
                </td>
              </tr>
              <tr>
                <td>
                  Reports and analytics at organization level{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  <img src={check} alert="check" />
                </td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <StickyTableHeader>
          <CompareTableHeader>Sales Process</CompareTableHeader>
        </StickyTableHeader>
        <TableWrapper>
          <table>
            <tbody>
              <tr>
                <td>
                  Create Multiple workflows&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Rule Engine for tasks and approvals&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Collaborate with multi-disciplinary teams&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Communicate with multi-disciplinary teams&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Document distribution with &nbsp;{" "}
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>{" "}
                  <br />
                  multi-disciplinary teams
                  {/* <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i> */}
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <StickyTableHeader>
          <CompareTableHeader>Team Management</CompareTableHeader>
        </StickyTableHeader>
        <TableWrapper>
          <table>
            <tbody>
              <tr>
                <td>
                  Create Teams&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Assign targets to your team&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                  <Remark>Annual only</Remark>{" "}
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                  <Remark>Annual / Quarterly / Monthly</Remark>{" "}
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Real-time team achievements data&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Team performance Dashboards and analytics&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Rich Visual Dashboards with Analytics&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>__</td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
        <StickyTableHeader>
          <CompareTableHeader>Support</CompareTableHeader>
        </StickyTableHeader>
        <TableWrapper>
          <table>
            <tbody>
              <tr>
                <td>
                  Email&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Priority On Call Support&nbsp;
                  <i
                    class="fa fa-info-circle"
                    style={{ color: "#c0bfbd", cursor: "pointer" }}
                    aria-hidden="true"
                    id="UncontrolledTooltipExample"
                  ></i>
                </td>
                <td>__</td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
                <td>
                  {" "}
                  <div>
                    <img src={check} alert="check" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </TableWrapper>
      </div>
    )
  }
}
export default Compare

const Sticky = styled.div`
  position: sticky;
  top: 60px;
  background-color: white;
  padding: 30px 0px;
  @media screen and (max-width: 600px) {
    padding: 20px 0px;
  }
`
const CompareSubHeaderMain = styled.div`
  display: flex;
  top: 0;
  width: 100%;
  /* border: 3px solid yellow; */
  justify-content: space-between;
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
  }
`
const CompareSubHeaderOne = styled.div`
  background: none;
  border: none;
  font-weight: 500;
  color: #333333;
  padding-left: 5%;
  width: 35%;
  text-align: center;
  /* border: 1px solid red; */
  font-size: 1.5em;
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    font-size: 0.7em;
  }
`

const CompareSubHeaderFree = styled.div`
  background: none;
  border: none;
  color: #333333;
  font-weight: 500;
  font-size: 1.375em;
  width: 15%;
  text-align: center;
  /* border: 1px solid red; */
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    font-size: 0.7em;
  }
`
const CompareSubHeaderStarter = styled.div`
  background: none;
  border: none;
  color: #333333;
  font-weight: 500;
  font-size: 1.375em;
  width: 15%;
  text-align: center;
  /* border: 1px solid red; */
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    font-size: 0.7em;
  }
`
const CompareSubHeaderProfessional = styled.div`
  background: none;
  border: none;
  color: #333333;
  font-weight: 500;
  font-size: 1.375em;
  width: 15%;
  text-align: center;
  /* border: 1px solid red; */
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    font-size: 0.7em;
  }
`
const CompareSubHeaderProfessionalPlus = styled.div`
  background: none;
  border: none;
  color: #333333;
  font-weight: 500;
  font-size: 1.375em;
  width: 15%;
  text-align: center;
  /* border: 1px solid red; */
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    font-size: 0.7em;
  }
`
const StickyTableHeader = styled.div`
  position: sticky;
  top: 140px;
  background-color: white;
  height: 45px;
  @media screen and (max-width: 600px) {
    top: 110px;
    height: 20px;
  }
`
const CompareTableHeader = styled.div`
  background: none;
  border: none;
  background-color: #fdefee;
  color: tomato;
  font-weight: 500;
  font-size: 1em;
  padding: 10px 50px;
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    padding: 10px 10px;
    font-size: 1em;
  }
`

const TableWrapper = styled.div`
  background-color: white;
`
const Remark = styled.div`
  font-size: 0.75em;
  font-family: "Roboto";

  color: #404346;
  @media screen and (max-width: 600px) {
    font-size: 0.4em;
    line-height: 10px;
  }
`
