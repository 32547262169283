import React, { useState } from "react"
import { Content2, Pricing, Box1 } from "../../components/UI"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import "./Pricing.css"
import { loginUrl } from "../../config/keys"
import CHECK from "../../images/check.png"
import UsePrice from "../../Hooks/UsePrice"
import styled from "styled-components"
// import SelectRange from "./Slider"
function PricingCards() {
  const [data] = useState(null)
  // const [open, setOpen] = useState(false)
  console.log(data)
  const { currency, basicAmount, proAmount, proplusAmount } = UsePrice()
  return (
    <div>
      {/* <div>
        <SelectRange />
      </div> */}
      {/* <div className="Heading"> Pick your growth plan</div> */}
      <Heading> Pick your growth plan</Heading>
      <Paragraph>
        Change your subscription, upgrade for more features with in-app
        purchases however subscription <br />
        whenever you need. Get started with your trial and experience the new
        way to sell more.{" "}
      </Paragraph>
      {/* <div>
        <button
          style={{
            border: "none",
            backgroundColor: "#1FC779",
            color: "white",
            marginLeft: "731px",
            padding: "11px 17px",
            fontSize: "16px",
          }}
        >
          RECOMMENDED
        </button>
      </div> */}

      <Pricing>
        <Box1>
          {/* <div className="banner_buttons_for_header"> */}
          <h2
            style={{
              marginTop: "6px",
              // color: "#4a4d57 !important",
              paddingTop: "12px",
              fontWeight: "600",
              fontSize: "28px",
            }}
          >
            Free
          </h2>
          {/* </div> */}
          <div className="pricing_blue_heading">
            <div className="pricing_blue_content">
              {" "}
              Limited to 3 users per enterprise including super user. No Credit
              card, required. Use it for free forever
            </div>
          </div>
          <Content2>
            <div className="price" style={{}}>
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "54px",

                  color: "#333333",
                }}
              >
                Free
              </h1>
              {/* <p>Forever</p> */}
            </div>

            <div className="pricingcontent"></div>

            <a
              href={`${loginUrl}/register/FREE`}
              className="banner_buttons3 "
              target="_blank"
            >
              Start Free Trial
            </a>
            <br />
            <br />
            {/* <p style={{ fontWeight: 600, marginBottom: 2, textAlign: "left" }}> */}
            <p
              style={{
                // lineHeight: 1.4,
                fontWeight: "bold",
                textAlign: "left",
                marginBottom: 10,
              }}
            >
              Contact management
            </p>
            {/* <p
              style={{
                fontSize: "13px",
                marginBottom: 10,
                textAlign: "left",
              }}
            > */}
            <p
              style={{
                textAlign: "left",
                fontSize: "13px",
              }}
            >
              <img src={CHECK} alt="blackCheck" />
              &nbsp; Map visualization to augment sales strategy
            </p>
            {/* <p
              style={{
                fontSize: "13px",
                marginBottom: 10,
                textAlign: "left",
              }}
            > */}
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              <img src={CHECK} alt="blackCheck" />
              &nbsp;Upto 1000 contacts/per user
            </p>
            {/* <p style={{ fontWeight: 600, marginBottom: 2, textAlign: "left" }}> */}
            <p
              style={{
                // lineHeight: 1.5,
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Multi-currency support
            </p>
            {/* <p
              style={{
                fontSize: "13px",
                marginBottom: 10,
                textAlign: "left",
              }}
            > */}
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              {" "}
              <img src={CHECK} alt="blackCheck" />
              &nbsp; Manage geo distributed opportunities
            </p>
            {/* <p
              style={{
                fontSize: "13px",
                marginBottom: 10,
                textAlign: "left",
              }}
            > */}
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              {" "}
              <img src={CHECK} alt="blackCheck" />
              &nbsp; Unified single currency reporting
            </p>

            {/* <p style={{ fontWeight: 600, marginBottom: 2, textAlign: "left" }}> */}
            <p
              style={{
                // lineHeight: 1.5,
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Funnel Management
            </p>
            {/* <p
              style={{
                fontSize: "13px",
                marginBottom: 10,
                textAlign: "left",
              }}
            > */}
            <p style={{ textAlign: "left", fontSize: "13px" }}>
              <img src={CHECK} alt="blackCheck" />
              &nbsp; Customizable Sales Process
            </p>
            {/* <p style={{ fontWeight: 600, marginBottom: 2, textAlign: "left" }}> */}
            <p
              style={{
                // lineHeight: 1.5,
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              Storage space of 100 MB/per user
            </p>
          </Content2>
        </Box1>

        <Box1>
          <h2
            style={{
              marginTop: "6px",
              // color: "#4a4d57 !important",
              paddingTop: "12px",
              fontWeight: "600",
              fontSize: "28px",
            }}
          >
            Starter
          </h2>
          {/* <h2>STARTER</h2> */}
          <div className="pricing_blue_heading">
            <div className="pricing_blue_content">
              Improve opportunity closures boost team productivity using
              out-of-the-box solutions. Get started in minutes!
            </div>
          </div>
          <Content2>
            <div className="price" style={{}}>
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "54px",
                  // marginTop: " 20px",
                  // marginBottom: "34px",

                  color: "#333333",
                }}
              >
                {currency === "USD" && <span>&#36;</span>}
                {currency === "EUR" && <span>&euro;</span>}
                {currency === "GBP" && <span>&#163;</span>}
                {currency === "INR" && <span>&#x20B9; </span>}
                {currency === "AUD" && <span>AU&#36;</span>}
                {currency === "CAD" && <span>CA&#36;</span>}
                {currency === "SGD" && <span>SG&#36; </span>}
                {basicAmount}
              </h1>
            </div>
            <div className="divider" />
            <div className="pricingcontent">
              {/* <p>{currency.toLowerCase()}/user/month*</p></div> */}
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
                per user / month
              </p>
            </div>

            <a
              onClick={(e) => {
                // To stop the page reloading
                // e.preventDefault()
                // Lets track that custom click
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category:
                    " Start your free trial  button in pricing for starter ",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "Click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label:
                    " Start your free trial  button in pricing for starter ",
                  // number - optional - Numeric value associated with the event. (e.g. A product ID)
                  value: 43,
                })
                //... Other logic here
              }}
              className="banner_buttons3 "
              href={`${loginUrl}/register/STARTER`}
              target="_blank"
            >
              Choose Starter
            </a>
            <br />
            <br />

            <p style={{ textAlign: "left" }}>
              {/* <p style={{ fontWeight: 600 }}> */}
              <p
                style={{
                  // lineHeight: 1.4,
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: 10,
                }}
              >
                Contact management
              </p>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                }}
              >
                <img src={CHECK} alt="blackCheck" />
                &nbsp; Map visualization to augment sales strategy
              </p>

              <p style={{ textAlign: "left", fontSize: "13px" }}>
                {/* Configure your accounting periods for real time analytics */}
                {/* <br /> */}
                <img src={CHECK} alt="blackCheck" />
                &nbsp; Upto 1000 contacts/per user
              </p>
              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Multi-currency support
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp; Manage geo distributed opportunities
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp; Unified single currency reporting
              </p>

              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {" "}
                Funnel Management
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp;Customizable Sales Process
                <br />
                {/* Limited download of reports */}
              </p>
              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                {" "}
                Storage space of 1 GB per user
              </p>
              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Configurable accounting periods
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp; Performance analysis visualized on the rich dashboard
              </p>
            </p>
          </Content2>
        </Box1>

        <Box1>
          <h2
            style={{
              marginTop: "6px",
              // color: "#4a4d57 !important",
              paddingTop: "12px",
              fontWeight: "600",
              fontSize: "28px",
            }}
          >
            Professional
          </h2>
          <div className="pricing_blue_heading">
            <div className="pricing_blue_content">
              Empower your sales team with automations, insights and
              perspectives to maximize your sales productivity.
            </div>
          </div>
          <Content2>
            {/* <h1> </h1> */}

            <div className="price" style={{}}>
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "54px",
                  // marginTop: " 20px",
                  // marginBottom: "34px",
                  color: "#333333",
                }}
              >
                {currency === "USD" && <span>&#36;</span>}
                {currency === "EUR" && <span>&euro;</span>}
                {currency === "GBP" && <span>&#163;</span>}
                {currency === "INR" && <span>&#x20B9; </span>}
                {currency === "AUD" && <span>AU&#36;</span>}
                {currency === "CAD" && <span>CA&#36;</span>}
                {currency === "SGD" && <span>SG&#36; </span>}
                {proAmount}
                <br />
              </h1>
            </div>
            <div className="divider" />
            <div className="pricingcontent">
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
                per user / month
              </p>
            </div>
            <a
              onClick={(e) => {
                // To stop the page reloading
                // e.preventDefault()
                // Lets track that custom click
                trackCustomEvent({
                  // string - required - The object that was interacted with (e.g.video)
                  category:
                    " Start your free trial  button in pricing for professional ",
                  // string - required - Type of interaction (e.g. 'play')
                  action: "Click",
                  // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                  label:
                    " Start your free trial  button in pricing for professional ",
                  // number - optional - Numeric value associated with the event. (e.g. A product ID)
                  value: 43,
                })
                //... Other logic here
              }}
              className="banner_buttons3 "
              href={`${loginUrl}/register/PROFESSIONAL`}
              // href='http://192.168.1.122:3000/proregister'
              target="_blank"
            >
              Choose Professional
              {/*     Start your 30 days free trial NOW! */}
            </a>
            <br />
            <br />
            <p style={{ textAlign: "left" }}>
              <p
                style={{
                  // lineHeight: 1.4,
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: 10,
                }}
              >
                All the features in Starter
                <div
                  style={{ fontWeight: 600, marginBottom: 2, marginTop: 10 }}
                >
                  Automation
                </div>{" "}
              </p>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                }}
              >
                <img src={CHECK} alt="blackCheck" />
                &nbsp;Lead distribution and communication
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp;Approvals based on opportunity value
                <br />
              </p>
              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Quotation
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp;Automated workflow for customer approvals
              </p>{" "}
              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Collaborate
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp;Create custom departments, collaborate with users from
                different disciplines
              </p>
              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Partner management
              </p>
              <p style={{ fontWeight: "bold", textAlign: "left" }}>Catalogue</p>
            </p>
          </Content2>
        </Box1>

        <Box1>
          <h2
            style={{
              marginTop: "6px",

              paddingTop: "12px",
              fontWeight: "600",
              fontSize: "28px",
            }}
          >
            Professional+
          </h2>
          <div className="pricing_blue_heading">
            <div className="pricing_blue_content">
              <p style={{ textAlign: "left", paddingTop: "15px" }}>
                Empower your sales team with automations, insights and
                perspectives to maximize your sales productivity.
              </p>
            </div>
          </div>
          <Content2>
            <div className="price" style={{}}>
              <h1
                style={{
                  fontWeight: "600",
                  fontSize: "54px",

                  color: "#333333",
                }}
              >
                {currency === "USD" && <span>&#36;</span>}
                {currency === "EUR" && <span>&euro;</span>}
                {currency === "GBP" && <span>&#163;</span>}
                {currency === "INR" && <span>&#x20B9; </span>}
                {currency === "AUD" && <span>AU&#36;</span>}
                {currency === "CAD" && <span>CA&#36;</span>}
                {currency === "SGD" && <span>SG&#36; </span>}
                {proplusAmount}
                <br />
              </h1>
            </div>
            <div className="divider" />
            <div className="pricingcontent">
              <p style={{ fontSize: "16px", fontWeight: "600" }}>
                per user / month
              </p>
            </div>

            <a
              className="banner_buttons3 "
              // style={{ cursor: "pointer", color: "white" }}
              // onClick={() => setOpen(true)}
              href={`${loginUrl}/register/PROFESSIONAL`}
              target="_blank"
            >
              Choose Professional+
            </a>
            <br />
            <br />
            <p style={{ textAlign: "left" }}>
              <p
                style={{
                  // lineHeight: 1.4,
                  fontWeight: "bold",
                  textAlign: "left",
                  marginBottom: 10,
                }}
              >
                All the features in Professional
                <div
                  style={{ fontWeight: 600, marginBottom: 2, marginTop: 10 }}
                >
                  Automation
                </div>{" "}
              </p>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                }}
              >
                <img src={CHECK} alt="blackCheck" />
                &nbsp;Lead distribution and communication
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp; Approvals based on opportunity value
              </p>
              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Quotation
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp;Automated workflow for customer approvals
              </p>

              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Collaborate
              </p>
              <p style={{ textAlign: "left", fontSize: "13px" }}>
                <img src={CHECK} alt="blackCheck" />
                &nbsp; Create custom departments, collaborate with users from
                different disciplines
              </p>
              <p
                style={{
                  // lineHeight: 1.5,
                  fontWeight: "bold",
                  textAlign: "left",
                }}
              >
                Partner management
              </p>
              <p style={{ fontWeight: "bold", textAlign: "left" }}>Catalogue</p>
            </p>
            <h1></h1>
            <br />
          </Content2>
        </Box1>
      </Pricing>
    </div>
  )
}

export default PricingCards

const Heading = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 2.75em;
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    font-size: 2.125em;
    // overflow-x: hidden;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    margin-top: 50px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    margin-top: 50px;
  }
`

const Paragraph = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 1.125em;
  font-family: "Roboto";

  @media screen and (max-width: 600px) {
    margin-top: 0.625em;
    // overflow-x: hidden;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
  }
`
