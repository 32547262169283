import React, { useState } from "react"

import "./Pricing.css"
import { LandingScreen } from "../Home/Styled"

import ContactusModal from "../Contactus/Contactus"
import { trackCustomEvent } from "gatsby-plugin-google-analytics"
import { loginUrl } from "../../config/keys"
import Compare from "./Compare"
import PricingImage from "../../images/base.png"
import PRICING from "../../images/mobilePricing.png"
import PricingBlueBox from "./PricingBlueBox"
import styled from "styled-components"
import PricingCards from "./PricingCards"
// import SliderBox from "./Slider"
function Homepage() {
  const [data] = useState(null)
  const [open, setOpen] = useState(false)
  console.log(data)

  return (
    <div>
      {/* <Container>
        <img
          src={PricingImage}
          alt="landingscreenimage"
          width="100%"
          // style={{ marginTop: "82px", width: "100%" }}
        />
        <Box>
          <TextOnImage>
            <Heading>Get the best out of your Sales and operations</Heading>
            <Paragraph>
              Whether you’re an early-stage startup or a global enterprise we’ve
              got plans for all. Migrate your data without any hassle, get
              started in minutes and take your revenue to new heights.
            </Paragraph>
          </TextOnImage>
        </Box>
      </Container> */}
      <Container>
        <img
          src={PricingImage}
          alt="landingscreenimage"
          width="100%"
          // style={{ marginTop: "82px", width: "100%" }}
        />
        <TextOnImage>
          {/* center */}
          <Heading>
            Get the best out of your Sales and Partner ecosystem
          </Heading>
          <Paragraph>
            Whether you’re an early-stage startup or a global enterprise we’ve
            got plans for all. Migrate your data without any hassle, get started
            in minutes and take your revenue to new heights.
          </Paragraph>
        </TextOnImage>
      </Container>
      <MobileView>
        <img src={PRICING} alt="mobileViewImage" width="100%" />
      </MobileView>

      {/* <LandingScreen
        backgroundImage={`url(${PricingImage})`}
        mobileBackgroundImage={`url(${PricingImage})`}
      >
        {" "}
        <div className="landing_image_main_box_pricing">
          <div className="landing_image_main_box_first_child_pricing">
            <div className="image_text_For_landing_page_pricing">
              Get the best out of your Sales and operations
              <p
                style={{
                  fontSize: "22px",
                  color: "white",
                  margin: "0px 100px",
                  fontWeight: "400",
                  textAlign: "center",
                }}
              >
                Whether you’re an early-stage startup or a global enterprise
                we’ve got plans for all. Migrate your data without any hassle,
                get started in minutes and take your revenue to new heights.
              </p>
            </div>

            <div className="button_for_landing_page_in_web">
              <a
                onClick={(e) => {
                  trackCustomEvent({
                    category: "start your free trial caraousel button ",

                    action: "Click",

                    label: "Start your free trial button in home page",

                    value: 43,
                  })
                }}
                className="banner_buttons"
                href={`${loginUrl}/register/Option`}
                target="_blank"
              >
                START YOUR 30 DAYS FREE TRIAL NOW!
              </a>
            </div>
          </div>
        </div>
      </LandingScreen> */}
      {/* <div className="landingHeader">
        
        <img src={PricingImage} alt="landingscreenimage" width="100%" />
      </div> */}
      {/* <div className="scale">How mature is your sales organization?</div> */}
      {/* <SliderBox /> */}
      <Spacer />
      <Spacer />
      <PricingCards />

      {/* <div className="Compare_heading">
        <CompareButton>
          Compare Plans &nbsp;<i className="fa fa-arrow-right"></i>
        </CompareButton>
      </div> */}

      <PricingBlueBox />

      <div style={{ marginTop: "40px" }}>
        <Compare />
      </div>
      {/* <div style={{ marginTop: "180px" }}></div>
      <ContactusModal open={open} toggle={() => setOpen(!open)} /> */}
    </div>
  )
}

export default Homepage

const CompareButton = styled.button`
  background-color: white;
  color: "#333333";
  font-size: 1.125em;
  font-weight: 500;
  font-family: "Roboto";
  padding: 10px;
`
const Container = styled.div`
  position: relative;
  margin-top: 60px;

  @media screen and (max-width: 600px) {
    /* display: flex; */
    flex-direction: column;
    justify-content: space-between;
    display: none;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: none;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: none;
  }
`
const Box = styled.div`
  text-align: center;
  /* height: 50vh;
  width: 50vw; */
`
const TextOnImage = styled.div`
  position: absolute;
  /* bottom: 100px;
  right: 100px;
  left: 100px; */
  text-align: center;
  color: white;
  top: 15%;
  padding: 0px 20%;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    position: center;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* font-weight: 300; */
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* font-weight: 300; */
  }
`
const Heading = styled.h2`
  font-size: 3em;
  font-weight: 500;
  font-family: "Roboto";
  @media screen and (max-width: 600px) {
    /* font-weight: 300; */
    font-size: 0.9em;
    margin-top: 1em;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    /* font-weight: 300; */
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    /* font-weight: 300; */
  }
`
const Paragraph = styled.p`
  font-size: 1.125em;
  font-weight: 100;
  font-family: "Roboto";
  margin-top: 3.5em;
  /* margin-left: 50px; */
  @media screen and (max-width: 600px) {
    font-weight: 300;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    font-weight: 300;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    font-weight: 300;
  }
`
const MobileView = styled.div`
  display: none;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    margin-top: 4em;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    display: flex;
    flex-direction: column;
    margin-top: 4em;
  }

  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: landscape) {
    display: flex;
    flex-direction: column;
    margin-top: 4em;
  }
`
const Spacer = styled.div`
  /* background-color: #eafaff; */
  height: 30px;
  @media screen and (max-width: 600px) {
    height: 30px;
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation: portrait) {
    height: 30px;
  }
`
